import axios from "axios";
import {Auth} from "aws-amplify";

/*export const OrchestratorInstance = axios.create({
  //baseURL: process.env.REACT_APP_TAG_ENDPOINT,
  proxy: {
    // Target URL for the proxy
    host: 'api.cyberating.tech/',
    port: 443,
    // Optional: You can also specify protocol and auth credentials if required
    protocol: 'https',
     auth: {
      username: 'username',
       password: 'password'
     }
  },
  withCredentials: true
});*/

async function getToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    // Handle error
    console.error("Error retrieving token:", error);
    return null;
  }
}

export const Instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to update headers with the token before each request
Instance.interceptors.request.use(async (config) => {
  const token = await getToken();
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


/*OrchestratorInstance.interceptors.request.use(async config => {
  config.headers = {
    //Authorization: 'Bearer ${token}',
    accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers
  };
  return config;
});*/

// export const Instance = axios.create({ baseURL: process.env.REACT_APP_TAG_ENDPOINT });


